import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  List as MuiList,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Divider as MuiDivider,
  Popover as MuiPopover,
  Tooltip as MuiTooltip,
  Collapse as MuiCollapse,
  ListItem as MuiListItem,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  ListItemText as MuiListItemText,
  TableSortLabel as MuiTableSortLabel,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Check as MuiCheckIcon,
  Link as MuiLinkIcon,
  Warning as MuiWarningIcon,
  ListAlt as MuiListAltIcon,
  AddCircle as MuiAddCircleIcon,
  Attachment as MuiAttachmentIcon,
  MonetizationOn as MuiMonetizationOnIcon,
  KeyboardArrowUp as MuiKeyboardArrowUpIcon,
  KeyboardArrowDown as MuiKeyboardArrowDownIcon,
  PlaylistAddRounded as MuiPlaylistAddRoundedIcon,
  DriveFileMove as MuiDriveFileMoveIcon,
} from "@mui/icons-material";
import { useUsers, useAlerts, Theme } from "common";
import {
  getAllCommitments,
  getComponentsByCommitmentId,
  getComponentWarningDetails,
} from "app/services/commitmentsService";
import { useCommitmentContext } from "app/services/commitmentContext";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import {
  LinkToPTARequestForm,
  ExpensesNotification,
} from "app/shared/constants";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";
import { MoveComponentDialog } from "./MoveComponentDialog";

function CollapsibleTable(props) {
  const { row } = props;
  const { t } = useTranslation();
  const { openCommitmentId, setOpenCommitmentId, filters } =
    useCommitmentContext();
  const [open, setOpen] = useState(
    (row.id === openCommitmentId && true) || false
  );
  const [components, setComponents] = useState([]);
  const [componentWarningDetails, setComponentWarningDetails] = useState([]);
  const [componentId, setComponentId] = useState();
  const [loading, setLoading] = useState(true);

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const [anchorEl, setAnchorEl] = useState(null);

  const { clearAlert, setAlert } = useAlerts();

  const handlePopoverOpen = (event, componentId) => {
    setAnchorEl(event.currentTarget);
    setComponentId(componentId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openWarningPopover = Boolean(anchorEl);

  useEffect(() => {
    openWarningPopover &&
      getComponentWarningDetails(
        setComponentWarningDetails,
        openCommitmentId,
        componentId,
        setAlert,
        clearAlert
      );
    // eslint-disable-next-line
  }, [openWarningPopover, componentId, openCommitmentId]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    if (row.id === openCommitmentId) {
      const project = filters.project || "";
      const task = filters.task || "";
      const award = filters.award || "";
      getComponentsByCommitmentId(
        setLoading,
        setComponents,
        openCommitmentId,
        project,
        task,
        award,
        setAlert,
        cancelSource
      );
    }
    // eslint-disable-next-line
    return () => {
      cancelSource.cancel();
    };
  }, [
    openCommitmentId,
    row.id,
    filters.project,
    filters.task,
    filters.award,
    setAlert,
  ]);

  const commitmentTableColumnsStr = "Commitments.mainView.list";
  const commitmentComponentTableColumnsStr =
    "Commitments.commitmentComponents.mainView.list";
  const uploadExpensesStr = "UploadedExpenses.mainView.list";
  const uploadTransferStr = "UploadedTransfers.mainView.list";

  const [isMoveComponentOpen, setIsMoveComponentOpen] = useState(false);
  const [moveComponentValue, setMoveComponentValue] = useState(null);

  const openMoveComponentDialog = (value) => {
    setIsMoveComponentOpen(true);
    setMoveComponentValue(value);
  };
  const closeMoveComponentDialog = () => {
    setIsMoveComponentOpen(false);
    setMoveComponentValue(null);
  };

  return (
    <>
      <MuiTableRow>
        <MuiTableCell>
          {row.componentCount > 0 && (
            <MuiIconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                setOpenCommitmentId(row.id);
              }}
            >
              {!open && <MuiKeyboardArrowDownIcon />}
              {open && <MuiKeyboardArrowUpIcon />}
            </MuiIconButton>
          )}
        </MuiTableCell>
        <MuiTableCell>
          <MuiTypography
            variant="body2"
            sx={{
              color: row.isClosed
                ? Theme.palette.error.main
                : Theme.palette.success.main,
            }}
          >
            {!row.isClosed &&
              t(`${commitmentTableColumnsStr}.tableValues.statusOpenText`)}
            {row.isClosed &&
              t(`${commitmentTableColumnsStr}.tableValues.statusClosedText`)}
          </MuiTypography>
        </MuiTableCell>
        <MuiTableCell align="left">
          {row.hasDocs && <MuiCheckIcon />}
        </MuiTableCell>
        <MuiTableCell align="left">
          <RouterLink
            style={{ textDecoration: "none" }}
            to={{
              pathname:
                "/commitments/" +
                new URL(row._links.self.href).pathname.split("/").pop() +
                "/view",
            }}
          >
            <MuiTooltip title={row.name} placement="left">
              <MuiTypography noWrap variant="body2">
                {row.name}
              </MuiTypography>
            </MuiTooltip>
          </RouterLink>
        </MuiTableCell>
        {permissions.PLACEHOLDER_COMMITMENT && (
          <MuiTableCell align="left">
            {row.commitmentType.toLowerCase() === "placeholder" && (
              <MuiCheckIcon />
            )}
          </MuiTableCell>
        )}
        <MuiTableCell align="left">
          <MuiTooltip title={row.departmentName} placement="left">
            <MuiTypography noWrap variant="body2">
              {row.departmentName}
            </MuiTypography>
          </MuiTooltip>
        </MuiTableCell>
        {permissions.MEMO_COMMITMENT && (
          <MuiTableCell align="left">
            {row.commitmentType.toLowerCase() === "memo" && <MuiCheckIcon />}
          </MuiTableCell>
        )}
        <MuiTableCell align="right">
          {CurrencyFormat(row.outstandingAmount)}
        </MuiTableCell>
        <MuiTableCell align="left" width="11%">
          <MuiGrid container justifyContent="space-around" flexWrap="nowrap">
            {row._links.edit && (
              <MuiGrid item>
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname:
                      "/commitments/" +
                      new URL(row._links.edit.href).pathname.split("/").pop() +
                      "/edit",
                  }}
                >
                  <MuiTooltip
                    title={t(
                      `${commitmentTableColumnsStr}.editCommitmentsTooltip`
                    )}
                  >
                    <MuiEditIcon fontSize="small" />
                  </MuiTooltip>
                </RouterLink>
              </MuiGrid>
            )}
            {permissions.VIEW_DOCUMENTS &&
              (permissions.ADD_DOCUMENTS || row.hasDocs) && (
                <MuiGrid item>
                  <MuiTooltip
                    title={t(
                      `${commitmentTableColumnsStr}.viewDocumentsTooltip`
                    )}
                  >
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/commitments/" +
                          new URL(row._links.self.href).pathname
                            .split("/")
                            .pop() +
                          "/documents",
                      }}
                    >
                      <MuiAttachmentIcon fontSize="small" />
                    </RouterLink>
                  </MuiTooltip>
                </MuiGrid>
              )}
            {permissions.CREATE_COMPONENTS && (
              <MuiGrid item>
                <MuiTooltip
                  title={t(`${commitmentTableColumnsStr}.addComponentTooltip`)}
                >
                  <RouterLink
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/commitments/" +
                        new URL(row._links.self.href).pathname
                          .split("/")
                          .pop() +
                        "/components/new",
                    }}
                  >
                    <MuiPlaylistAddRoundedIcon fontSize="small" />
                  </RouterLink>
                </MuiTooltip>
              </MuiGrid>
            )}
            {permissions.VIEW_COMPONENTS && (
              <MuiGrid item>
                <MuiTooltip
                  title={t(
                    `${commitmentTableColumnsStr}.viewCommitmentsTooltip`
                  )}
                >
                  <RouterLink
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/commitments/" +
                        new URL(row._links.self.href).pathname
                          .split("/")
                          .pop() +
                        "/view",
                    }}
                  >
                    <MuiListAltIcon fontSize="small" />
                  </RouterLink>
                </MuiTooltip>
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiTableCell>
      </MuiTableRow>

      <MuiTableRow
        key={row.id}
        sx={{
          backgroundColor: Theme.palette.primary.light,
          "& .MuiTableCell-head": {
            color: Theme.palette.grey[700],
            fontSize: 13,
            fontWeight: 600,
          },
          "& .MuiTableCell-body": {
            borderBottom: "unset",
            fontSize: 13,
            fontWeight: 500,
          },
          "& .MuiTableCell-sizeSmall": { padding: "6px 5px 6px 5px" },
        }}
      >
        <MuiTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 40,
            paddingRight: 20,
          }}
          colSpan={getColSpan(permissions)}
        >
          <MuiCollapse in={open} timeout="auto" unmountOnExit>
            <MuiBox>
              <MuiTable size="small" aria-label="purchases">
                <MuiTableHead>
                  <MuiTableRow>
                    <MuiTableCell width="30px"></MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.pta`
                      )}
                    </MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.componentName`
                      )}
                    </MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.date`
                      )}
                    </MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.description`
                      )}
                    </MuiTableCell>
                    <MuiTableCell align="right">
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.unspentAmount`
                      )}
                    </MuiTableCell>
                    <MuiTableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.status`
                      )}
                    </MuiTableCell>
                    <MuiTableCell align="center">
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.actions`
                      )}
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {loading && (
                    <MuiTableRow>
                      <MuiTableCell colSpan={getColSpan(permissions)}>
                        <MuiTypography
                          variant="body2"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          {t("globals.list.messages.fetchingInfo")}
                        </MuiTypography>
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                  {components.map((row, componentsIndex) => (
                    <MuiTableRow key={componentsIndex}>
                      <MuiTableCell component="th" scope="row">
                        {row.componentWarning && (
                          <>
                            <MuiWarningIcon
                              fontSize="small"
                              onClick={(e) => {
                                handlePopoverOpen(e, row.id);
                              }}
                              sx={{
                                cursor: "help",
                                color: Theme.palette.warning.main,
                              }}
                            />
                            {componentWarningDetails.length > 0 && (
                              <MuiPopover
                                id="mouse-over-popover"
                                open={openWarningPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <MuiList
                                  sx={{
                                    width: "515px",
                                    background: Theme.palette.grey[700],
                                    color: Theme.palette.common.white,
                                  }}
                                >
                                  <MuiListItem alignItems="center" dense>
                                    <MuiListItemText
                                      disableTypography
                                      primary={
                                        <MuiGrid container>
                                          <MuiGrid item xs={12}>
                                            <MuiTypography align="center">
                                              {t(
                                                `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                                              )}
                                            </MuiTypography>
                                          </MuiGrid>
                                        </MuiGrid>
                                      }
                                    />
                                  </MuiListItem>
                                </MuiList>
                                {componentWarningDetails.map(
                                  (warningDetailsRow, warningDetailsIndex) => (
                                    <Fragment key={warningDetailsIndex}>
                                      <MuiList>
                                        <MuiListItem
                                          alignItems="flex-start"
                                          dense
                                        >
                                          <MuiListItemText
                                            disableTypography
                                            secondary={
                                              <MuiGrid container>
                                                <MuiGrid item xs={3}>
                                                  <MuiTypography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      "Commitments.financialHistory.mainView.list.glPeriodTable.tableColumns.glPeriod"
                                                    )}
                                                    :
                                                  </MuiTypography>
                                                </MuiGrid>
                                                <MuiGrid item xs={9}>
                                                  <MuiTypography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.glPeriod}
                                                  </MuiTypography>
                                                </MuiGrid>
                                              </MuiGrid>
                                            }
                                          />
                                        </MuiListItem>
                                        <MuiListItem
                                          alignItems="flex-start"
                                          dense
                                        >
                                          <MuiListItemText
                                            disableTypography
                                            secondary={
                                              <MuiGrid container>
                                                <MuiGrid item xs={3}>
                                                  <MuiTypography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${uploadTransferStr}.redistributeTransfersDialog.tableColumns.monthlyTransferDetail.source`
                                                    )}
                                                    :
                                                  </MuiTypography>
                                                </MuiGrid>
                                                <MuiGrid item xs={9}>
                                                  <MuiTypography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.source}
                                                  </MuiTypography>
                                                </MuiGrid>
                                              </MuiGrid>
                                            }
                                          />
                                        </MuiListItem>
                                        <MuiListItem
                                          alignItems="flex-start"
                                          dense
                                        >
                                          <MuiListItemText
                                            disableTypography
                                            secondary={
                                              <MuiGrid container>
                                                <MuiGrid item xs={3}>
                                                  <MuiTypography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${uploadTransferStr}.tableColumns.amount`
                                                    )}
                                                    :
                                                  </MuiTypography>
                                                </MuiGrid>
                                                <MuiGrid item xs={9}>
                                                  <MuiTypography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.amount !==
                                                      undefined &&
                                                      CurrencyFormat(
                                                        warningDetailsRow.amount
                                                      )}
                                                  </MuiTypography>
                                                </MuiGrid>
                                              </MuiGrid>
                                            }
                                          />
                                        </MuiListItem>
                                        <MuiListItem alignItems="flex-start">
                                          <MuiListItemText
                                            disableTypography
                                            secondary={
                                              <MuiGrid container>
                                                <MuiGrid item xs={3}>
                                                  <MuiTypography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                                                    )}
                                                    :
                                                  </MuiTypography>
                                                </MuiGrid>
                                                <MuiGrid item xs={9}>
                                                  {warningDetailsRow?.warnings?.map(
                                                    (anObjectMapped, index) => {
                                                      return (
                                                        <MuiTypography
                                                          variant="subtitle1"
                                                          color="textSecondary"
                                                          key={index}
                                                        >
                                                          {anObjectMapped}
                                                        </MuiTypography>
                                                      );
                                                    }
                                                  )}
                                                </MuiGrid>
                                              </MuiGrid>
                                            }
                                          />
                                        </MuiListItem>
                                        <MuiListItem
                                          alignItems="flex-start"
                                          padding={0}
                                        >
                                          <MuiListItemText
                                            disableTypography
                                            secondary={
                                              <MuiGrid container>
                                                <MuiGrid item xs={12}>
                                                  <MuiTypography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      ExpensesNotification[
                                                        warningDetailsRow
                                                          .notificationStatus
                                                      ]
                                                    )}{" "}
                                                    {t(
                                                      `${uploadExpensesStr}.notificationStatusTooltip.by`
                                                    )}{" "}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "UNRESOLVED" &&
                                                      warningDetailsRow.unresolvedBy}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "INPROCESS" &&
                                                      warningDetailsRow.inprocessBy}{" "}
                                                    {t(
                                                      `${uploadExpensesStr}.notificationStatusTooltip.on`
                                                    )}{" "}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "UNRESOLVED" &&
                                                      warningDetailsRow.unresolvedDate}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "INPROCESS" &&
                                                      warningDetailsRow.inprocessDate}
                                                  </MuiTypography>
                                                </MuiGrid>
                                              </MuiGrid>
                                            }
                                          />
                                        </MuiListItem>
                                      </MuiList>
                                      <MuiDivider />
                                    </Fragment>
                                  )
                                )}
                              </MuiPopover>
                            )}
                          </>
                        )}
                      </MuiTableCell>
                      <MuiTableCell>
                        {PTAFormat(row.project, row.task, row.award)}
                      </MuiTableCell>
                      <MuiTableCell>
                        <MuiTooltip title={row.componentName} placement="left">
                          <MuiTypography variant="caption" component="div">
                            {row.componentName}
                          </MuiTypography>
                        </MuiTooltip>
                      </MuiTableCell>
                      <MuiTableCell>{`${row.componentDate} ${
                        row?.componentIncrementals?.length > 1 ? "(M)" : ""
                      }`}</MuiTableCell>
                      <MuiTableCell>
                        <MuiTooltip
                          title={row.componentDescription}
                          placement="left"
                        >
                          <MuiTypography variant="caption" component="div">
                            {row.componentDescription}
                          </MuiTypography>
                        </MuiTooltip>
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        {!row.isClosed &&
                          CurrencyFormat(row.totalUnspentAmount)}
                      </MuiTableCell>
                      <MuiTableCell>
                        <MuiTypography
                          variant="caption"
                          sx={{
                            color: row.isClosed
                              ? Theme.palette.error.main
                              : Theme.palette.success.main,
                          }}
                        >
                          {!row.isClosed &&
                            t(
                              `${commitmentComponentTableColumnsStr}.tableValues.statusOpenText`
                            )}
                          {row.isClosed &&
                            t(
                              `${commitmentComponentTableColumnsStr}.tableValues.statusClosedText`
                            )}
                        </MuiTypography>
                      </MuiTableCell>
                      <MuiTableCell align="center" width="9%">
                        <MuiGrid container justifyContent="space-around">
                          {row._links.edit && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={`${
                                  new URL(row._links.edit.href).pathname
                                }/edit`}
                              >
                                <MuiTooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.editCommitmentComponentsTooltip`
                                  )}
                                >
                                  <MuiEditIcon fontSize="small" />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                          {row._links.view && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={`${
                                  new URL(row._links.self.href).pathname
                                }/view`}
                              >
                                <MuiTooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.viewComponentsTooltip`
                                  )}
                                >
                                  <MuiListAltIcon fontSize="small" />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                          {row._links.moveLinkComponent && (
                            <MuiGrid item>
                              <MuiTooltip
                                title={t(
                                  `${commitmentComponentTableColumnsStr}.moveComponentTooltip`
                                )}
                              >
                                <MuiIconButton
                                  sx={{ padding: 0, margin: 0 }}
                                  onClick={() => {
                                    openMoveComponentDialog(row);
                                  }}
                                >
                                  <MuiDriveFileMoveIcon fontSize="small" />
                                </MuiIconButton>
                              </MuiTooltip>
                            </MuiGrid>
                          )}
                          {row._links.viewFinancialDetail && (
                            <MuiGrid item>
                              <RouterLink
                                style={{ textDecoration: "none" }}
                                to={`${
                                  new URL(row._links.self.href).pathname
                                }/financial-history`}
                              >
                                <MuiTooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.financialHistoryTooltip`
                                  )}
                                >
                                  <MuiMonetizationOnIcon fontSize="small" />
                                </MuiTooltip>
                              </RouterLink>
                            </MuiGrid>
                          )}
                        </MuiGrid>
                      </MuiTableCell>
                    </MuiTableRow>
                  ))}
                </MuiTableBody>
              </MuiTable>
            </MuiBox>
          </MuiCollapse>
        </MuiTableCell>
      </MuiTableRow>
      <MoveComponentDialog
        open={isMoveComponentOpen}
        closeMoveComponentDialog={closeMoveComponentDialog}
        currentComponent={moveComponentValue}
        currentCommitment={row}
      />
    </>
  );
}

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  return `${project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-${
    task ? task : "\u00A0\u00A0\u00A0"
  }-${award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"}`;
}

function EnhancedTableHead(props) {
  const { order, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { sort } = useCommitmentContext();

  const commitmentTableColumnsStr = "Commitments.mainView.list.tableColumns";
  return (
    <MuiTableHead sx={whiteTableHeaderLabel}>
      <MuiTableRow>
        <MuiTableCell align="left" width="42px"></MuiTableCell>
        <MuiTableCell
          align="left"
          key="status"
          width="65px"
          sortDirection={sort.column === "isClosed" ? sort.orderBy : false}
        >
          <MuiTableSortLabel
            active={sort.column === "isClosed"}
            direction={sort.column === "isClosed" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isClosed")}
          >
            {t(`${commitmentTableColumnsStr}.status`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="docs"
          width="60px"
          sortDirection={sort.column === "hasDocs" ? sort.orderBy : false}
        >
          <MuiTableSortLabel
            active={sort.column === "hasDocs"}
            direction={sort.column === "hasDocs" ? sort.orderBy : "asc"}
            onClick={createSortHandler("hasDocs")}
          >
            {t(`${commitmentTableColumnsStr}.docs`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="commitmentName"
          sortDirection={sort.column === "name" ? sort.orderBy : "asc"}
        >
          <MuiTableSortLabel
            active={sort.column === "name"}
            direction={sort.column === "name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("name")}
          >
            {t(`${commitmentTableColumnsStr}.commitmentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        {permissions.PLACEHOLDER_COMMITMENT && (
          <MuiTableCell
            align="left"
            key="placeholder"
            width="110px"
            sortDirection={sort.column === "placeholder" ? sort.orderBy : false}
          >
            <MuiTableSortLabel
              active={sort.column === "placeholder"}
              direction={sort.column === "placeholder" ? sort.orderBy : "asc"}
              onClick={createSortHandler("placeholder")}
            >
              {t(`${commitmentTableColumnsStr}.placeholder`)}
            </MuiTableSortLabel>
          </MuiTableCell>
        )}
        <MuiTableCell
          align="left"
          key="deptName"
          sortDirection={sort.column === "org.orgName" ? order : false}
        >
          <MuiTableSortLabel
            active={sort.column === "org.orgName"}
            direction={sort.column === "org.orgName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("org.orgName")}
          >
            {t(`${commitmentTableColumnsStr}.departmentName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        {permissions.MEMO_COMMITMENT && (
          <MuiTableCell
            align="left"
            key="memo"
            width="70px"
            sortDirection={sort.column === "memo" ? sort.orderBy : false}
          >
            <MuiTableSortLabel
              active={sort.column === "memo"}
              direction={sort.column === "memo" ? sort.orderBy : "asc"}
              onClick={createSortHandler("memo")}
            >
              {t(`${commitmentTableColumnsStr}.memo`)}
            </MuiTableSortLabel>
          </MuiTableCell>
        )}
        <MuiTableCell
          align="right"
          key="outstandingAmount"
          width="120px"
          sortDirection={
            sort.column === "outstandingAmount" ? sort.orderBy : false
          }
        >
          <MuiTableSortLabel
            active={sort.column === "outstandingAmount"}
            direction={
              sort.column === "outstandingAmount" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("outstandingAmount")}
          >
            {t(`${commitmentTableColumnsStr}.outstandingAmount`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="center" key="actions" width="115px">
          {t(`${commitmentTableColumnsStr}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Commitments = (props) => {
  const { t } = useTranslation();

  const { filters, sort, setSort, pagination, setPagination } =
    useCommitmentContext();
  const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage);
  const [page, setPage] = useState(pagination.page);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState(sort.orderBy);
  const [orderBy, setOrderBy] = useState(sort.column);

  const [commitments, setCommitments] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaults = {
    pagination: [10, 25, 50, 100],
  };

  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { clearAlert, setAlert } = useAlerts();

  useEffect(() => {
    if (permissions.VIEW_COMMITMENTS) {
      const dealName = filters.dealName || "";
      const departmentValue = filters.departmentValue || "";
      const status = filters.status || "";
      const project = filters.project || "";
      const task = filters.task || "";
      const award = filters.award || "";

      getAllCommitments(
        setCommitments,
        setTotalCount,
        setLoading,
        setAlert,
        clearAlert,
        {
          page,
          rowsPerPage,
          orderBy,
          order,
          dealName,
          departmentValue,
          status,
          project,
          task,
          award,
        }
      );
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    permissions,
    filters.dealName,
    filters.departmentValue,
    filters.status,
    filters.project,
    filters.task,
    filters.award,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  const CommitmentActionButton = () => {
    const commitmentButtonStr = "Commitments.mainView";
    if (permissions.CREATE_COMMITMENTS) {
      return (
        <MuiGrid container justifyContent="flex-end">
          <RouterLink style={{ textDecoration: "none" }} to="/commitments/new">
            <MuiButton
              variant="contained"
              color="primary"
              startIcon={<MuiAddCircleIcon />}
            >
              {t(`${commitmentButtonStr}.ctaTextForCreateCommitment`)}
            </MuiButton>
          </RouterLink>
        </MuiGrid>
      );
    } else if (!permissions.CREATE_COMMITMENTS) {
      return (
        <MuiGrid container justifyContent="flex-end">
          <RouterLink
            style={{ textDecoration: "none" }}
            to={LinkToPTARequestForm}
            target="_blank"
          >
            <MuiButton
              variant="contained"
              color="primary"
              startIcon={<MuiLinkIcon />}
            >
              {t(`${commitmentButtonStr}.ctaTextForLinkToPTARequestForm`)}
            </MuiButton>
          </RouterLink>
        </MuiGrid>
      );
    } else {
      return null;
    }
  };

  return (
    <MuiGrid
      container
      alignItems="flex-start"
      justifyContent="flex-end"
      direction="row"
      spacing={2}
    >
      <MuiGrid item xs={12}>
        <CommitmentActionButton />
      </MuiGrid>

      {permissions.VIEW_COMMITMENTS && (
        <MuiGrid item xs={12}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable
              aria-label="Commitment table"
              stickyHeader
              sx={{ tableLayout: "fixed" }}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <MuiTableBody>
                {loading && (
                  <MuiTableRow>
                    <MuiTableCell
                      colSpan={getColSpan(permissions)}
                      sx={{ textAlign: "center" }}
                    >
                      <MuiCircularProgress />
                    </MuiTableCell>
                  </MuiTableRow>
                )}

                {!loading &&
                  commitments.map((row, index) => (
                    <CollapsibleTable row={row} key={index} />
                  ))}
                {!loading && !commitments.length && (
                  <MuiTableRow>
                    <MuiTableCell colSpan={getColSpan(permissions)}>
                      <MuiTypography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </MuiTableBody>
            </MuiTable>
            <MuiTablePagination
              rowsPerPageOptions={defaults.pagination}
              component="div"
              count={totalCount}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};

const getColSpan = (permissions) => {
  let numColumns = 7;
  permissions.PLACEHOLDER_COMMITMENT && numColumns++;
  permissions.MEMO_COMMITMENT && numColumns++;
  return numColumns;
};
