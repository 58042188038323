import { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Autocomplete as MuiAutocomplete,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import {
  ArrowBack as MuiArrowBackIcon,
  Attachment as MuiAttachmentIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Alert, Theme, useAlerts, useUsers } from "common";
import {
  getCommitmentByIdSummary,
  getOrgByMatch,
  getCoInvestmentByMatch,
  getAllPrograms,
  getAllUnits,
  getAllPresentations,
  postCommitment,
  putCommitment,
  prepareCommitmentData,
} from "app/services/commitmentsService";
import { getIsRequiredText } from "app/shared/utils";
import { CommitmentTypes } from "app/shared/constants";
import { FormButton } from "app/shared/ui/FormButton";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";
import { CommitmentPresentationSubcategoryField } from "./CommitmentPresentationSubcategoryField";
import { CommitmentProjectGroupField } from "./CommitmentProjectGroupField";

/**
 * Component export definition for `CommitmentForm`
 * It will be used as a form to create and edit a commitment
 *
 * */
export const CommitmentForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  const viewOnly = props.pageType === "view";
  const shrinkProps = viewOnly && { shrink: true };
  const otherParent = props.parent === "other";
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const returnRoute = location.pathname.split("/").filter(Boolean)[0];

  const handleClose = () => {
    navigate(`/${returnRoute}`);
  };

  const _handleSubmit = (values, setFieldError) => {
    const cancelSource = axios.CancelToken.source();
    values = prepareCommitmentData(values, formPreFetchData, null);

    // Defines what to do after saving the data
    const afterSave = () => {
      navigate(`/${returnRoute}`);

      const textKey = params.CommitmentId
        ? "Commitments.create.notification.update"
        : "Commitments.create.notification.success";
      setAlert(
        "success",
        t(textKey, { commitmentName: `"${values.name.trim()}"` }),
        true
      );
    };

    params.CommitmentId
      ? putCommitment(
          values,
          params.CommitmentId,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        )
      : postCommitment(
          values,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        );
  };

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const { clearAlert, setAlert, alert } = useAlerts();

  const formFieldsStr = "Commitments.create.form.fields";
  const commitmentCreateStr = "Commitments.create";
  const commitmentViewStr = "Commitments.view";
  const commitmentEditStr = "Commitments.edit";
  const commitmentComponentList =
    "Commitments.commitmentComponents.mainView.list";

  const [, setLoading] = useState(false);

  // Units
  const [units, setUnits] = useState([]);

  // Programs
  const [programs, setPrograms] = useState([]);

  // Presentations
  const [presentations, setPresentations] = useState([]);

  // Controllable state for the form field `Co-Investment Entity`
  const [coInvestmentValue, setCoInvestmentValue] = useState("");
  const [coInvestmentInputValue, setCoInvestmentInputValue] = useState("");
  // State variable and its function for orgOptions
  const [orgOptions, setOrgOptions] = useState([]);

  // State variable and its function for Co-Investment Entity
  const [coInvestmentOptions, setCoInvestmentOptions] = useState([]);

  const [departmentNameValue, setDepartmentNameValue] = useState({
    orgName: "",
    orgCode: "",
  });

  /**
   * `formDataResponse` - Use to store the response of create/edit form data
   * `setFormDataResponse` - Use to set/modify the value of state/variable formDataResponse
   *
   * */
  const formPreFetchData = {
    commitmentType: "REGULAR", //Enum implementation
  };
  const [showMemoAmount, setShowMemoAmount] = useState(false);
  const [hasDocument, setHasDocument] = useState({});
  const [formDataResponse, setFormDataResponse] = useState({
    // Object keys use to create/edit form data
    name /* Commitment Name */: "",
    departmentName /* Department Name */: "",
    commitmentType /* Commitment Type */: "",
    projectGroup /* Project Group */: "",
    unit /* Unit */: null,
    program /* Program */: null,
    presentation /* Presentation */: null,
    presentationSubcategory /* Presentation Sub Category */: null,
    commitmentNotes /* Commitment Notes */: "",
    isClosed /* Closed */: false,
    closedNotes: "",
    closedDate: new Date(),
    withdrawn: false,
    memoAmount: 0.0,
    orgCode: "",
    hasDocs: false,
    outstandingAmount: "",
  });
  const commitmentValidation = Yup.object().shape({
    name: Yup.string().required(
      t(`${formFieldsStr}.commitmentName.validation.isEmpty`)
    ),
    departmentName: Yup.mixed().required(
      t(`${formFieldsStr}.departmentName.validation.isEmpty`)
    ),
    commitmentType: Yup.string(),
    memoAmount: Yup.number().when("commitmentType", {
      is: "MEMO",
      then: (schema) =>
        schema.required(t(`${formFieldsStr}.memoAmount.validation.isEmpty`)),
      otherwise: (schema) => schema.nullable(),
    }),
    projectGroup: Yup.object().required(
      t(`${formFieldsStr}.projectGroup.validation.isEmpty`)
    ),
    unit: Yup.mixed().required(
      t(`${formFieldsStr}.commitmentUnit.validation.isEmpty`)
    ),
    program: Yup.mixed().required(
      t(`${formFieldsStr}.program.validation.isEmpty`)
    ),
    presentation: Yup.mixed().required(
      t(`${formFieldsStr}.presentation.validation.isEmpty`)
    ),
    isClosed: Yup.boolean(),
    closedDate: Yup.date().when("isClosed", {
      is: true,
      then: (schema) =>
        schema
          .required(t(`${formFieldsStr}.closedDate.validation.isEmpty`))
          .typeError(t(`${formFieldsStr}.closedDate.validation.invalidDate`)),
      otherwise: (schema) => schema.nullable(),
    }),
    closedNotes: Yup.string().when("isClosed", {
      is: true,
      then: (schema) =>
        schema.required(t(`${formFieldsStr}.closedNotes.validation.isEmpty`)),
      otherwise: (schema) => schema.nullable(),
    }),
    withdrawn: Yup.boolean().when("isClosed", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    coInvestmentEntity: Yup.mixed().when("presentationSubcategory", {
      is: (i) => i && i.name && i.name.toLowerCase() === "co-investment",
      then: (schema) =>
        schema.required(
          t(`${formFieldsStr}.coInvestmentEntity.validation.isEmpty`)
        ),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const [unitError, setUnitError] = useState({
    exist: false,
    message: null,
  });

  const [programError, setProgramError] = useState({
    exist: false,
    message: null,
  });

  const [presentationError, setPresentationError] = useState({
    exist: false,
    message: null,
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    params.CommitmentId &&
      getCommitmentByIdSummary(
        params.CommitmentId,
        {
          setFormDataResponse,
          setHasDocument,
          setDepartmentNameValue,
          setLoading,
        },
        {
          setAlert,
          clearAlert,
        },
        cancelSource
      );
    getAllPrograms(setPrograms, setProgramError);
    getAllUnits(setUnits, setUnitError);
    getAllPresentations(setPresentations, setPresentationError);
    return () => {
      cancelSource.cancel();
    };
  }, [clearAlert, params.CommitmentId, setAlert]);

  function viewClosedWithdrawnStatus(closed, withdrawn) {
    if (closed && withdrawn) {
      return t(
        `${commitmentComponentList}.tableValues.statusClosedWithdrawnText`
      );
    } else if (closed) {
      return t(`${commitmentComponentList}.tableValues.statusClosedText`);
    } else {
      return t(`${commitmentComponentList}.tableValues.statusOpenText`);
    }
  }

  return (
    ((props.pageType === "new" && permissions.CREATE_COMMITMENTS) ||
      ((props.pageType === "edit" || props.pageType === "newTab") &&
        permissions.EDIT_COMMITMENTS) ||
      (props.pageType === "view" && permissions.VIEW_COMMITMENTS)) && (
      <MuiGrid
        container
        sx={{
          margin: "0px 16px",
          width: "calc(100% - 32px)",
        }}
      >
        {props.pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  to={`/commitments`}
                  style={{ textDecoration: "none" }}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("Commitments.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {props.pageType === "new"
                    ? t(`${commitmentCreateStr}.breadcrumbTitle`)
                    : viewOnly
                    ? t(`${commitmentViewStr}.breadcrumbTitle`)
                    : t(`${commitmentEditStr}.breadcrumbTitle`)}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>

            {viewOnly && !otherParent && (
              <MuiGrid
                item
                container
                xs={4}
                justifyContent="flex-end"
                spacing={2}
              >
                <MuiButton
                  startIcon={<MuiArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  {t(`${commitmentViewStr}.goBackButtonLabel`)}
                </MuiButton>
              </MuiGrid>
            )}
          </MuiGrid>
        )}
        <MuiGrid item xs={12} sx={{ padding: "8px 0" }}>
          <MuiTypography variant="h1">
            {props.pageType === "new"
              ? t(`${commitmentCreateStr}.title`)
              : viewOnly
              ? `${t(`${commitmentViewStr}.title`)} "${formDataResponse.name}"`
              : `${t(`${commitmentEditStr}.title`)} "${formDataResponse.name}"`}
          </MuiTypography>
        </MuiGrid>

        <MuiGrid item xs={12}>
          {alert.exists && <Alert />}
        </MuiGrid>

        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment */}
        <Formik
          // initialValues - User to store the Formik form's initial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError);
          }}
          validationSchema={commitmentValidation}
        >
          {(props) => {
            const /** !Object */ {
                values,
                touched,
                errors,
                dirty,
                isValid,
                handleBlur,
                handleChange,
                handleSubmit,
                setValues,
                setFieldValue,
                setFieldError,
              } = props;
            return (
              // Native form element to submit the form values
              <MuiGrid item xs={12}>
                <form onSubmit={handleSubmit}>
                  <MuiGrid
                    item
                    container
                    xs={10}
                    direction="column"
                    spacing={2}
                  >
                    <MuiGrid item xs={12} container justifyContent="flex-end">
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">
                          {t(
                            "Commitments.commitmentComponents.create.form.fields.unspentComponent.label"
                          )}
                          {`: `}
                          {CurrencyFormat(values.outstandingAmount)}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    {props.pageType !== "new" &&
                      permissions.VIEW_DOCUMENTS &&
                      hasDocument.hasDocs && (
                        <MuiGrid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          <MuiGrid item xs={12}>
                            <MuiTypography align="right">
                              {hasDocument.documentCount}{" "}
                              {t(`${commitmentCreateStr}.documentCount`)}
                              <MuiAttachmentIcon
                                sx={{ verticalAlign: "sub" }}
                              />
                            </MuiTypography>
                          </MuiGrid>
                        </MuiGrid>
                      )}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Commitment Name - MuiTextField Input */}
                      <MuiGrid item xs={6}>
                        <MuiTextField
                          id="name"
                          name="name"
                          label={t(`${formFieldsStr}.commitmentName.label`)}
                          required={!viewOnly}
                          value={values.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          helperText={touched.name ? errors.name : ""}
                          error={touched.name && Boolean(errors.name)}
                          variant={viewOnly ? "standard" : "outlined"}
                          disabled={viewOnly}
                          autoComplete="off"
                          inputProps={{ maxLength: 100 }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6} />
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Department Name - MuiAutocomplete Input */}
                      <MuiGrid item xs={6}>
                        <MuiAutocomplete
                          id="departmentName"
                          name="departmentName"
                          options={orgOptions || []}
                          getOptionLabel={(option) =>
                            option?.orgName && option?.orgCode
                              ? `${option.orgName} (${option.orgCode})`
                              : ""
                          }
                          value={departmentNameValue}
                          clearIcon={false}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setDepartmentNameValue(newValue);
                            setValues({
                              ...values,
                              departmentName: newValue,
                              orgCode: newValue?.orgCode,
                            });
                          }}
                          onInputChange={(event, newInputValue) => {
                            getOrgByMatch(
                              "",
                              newInputValue,
                              setOrgOptions,
                              setLoading,
                              setFieldError
                            );
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              helperText={
                                touched.departmentName
                                  ? errors.departmentName
                                  : ""
                              }
                              error={
                                touched.departmentName &&
                                Boolean(errors.departmentName)
                              }
                              sx={disabledTextFieldStyles}
                              label={t(`${formFieldsStr}.departmentName.label`)}
                              required={!viewOnly}
                              variant={viewOnly ? "standard" : "outlined"}
                            />
                          )}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6} />
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Commitment Type - MuiAutocomplete Input */}
                        <MuiAutocomplete
                          id="commitmentType"
                          name="commitmentType"
                          options={Object.values(CommitmentTypes).filter(
                            (x) => x !== CommitmentTypes["REQUEST"]
                          )}
                          getOptionLabel={(option) => t(option)}
                          isOptionEqualToValue={(option, value) =>
                            t(option) === value
                          }
                          value={
                            values.commitmentType
                              ? t(CommitmentTypes[values.commitmentType])
                              : t(
                                  CommitmentTypes[
                                    formPreFetchData.commitmentType
                                  ]
                                )
                          }
                          fullWidth
                          clearIcon={false}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onBlur={handleBlur}
                          onChange={(event, value) => {
                            setFieldValue(
                              "commitmentType",
                              Object.keys(CommitmentTypes).find(
                                (key) => CommitmentTypes[key] === value
                              )
                            );

                            if (
                              Object.keys(CommitmentTypes).find(
                                (key) => CommitmentTypes[key] === value
                              ) === "MEMO"
                            ) {
                              setShowMemoAmount(true);
                              setFieldValue("memoAmount", 0.0);
                            } else {
                              setShowMemoAmount(false);
                              setFieldValue("memoAmount", 0.0);
                            }
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(`${formFieldsStr}.commitmentType.label`)}
                              required={!viewOnly}
                              variant={viewOnly ? "standard" : "outlined"}
                              sx={disabledTextFieldStyles}
                            />
                          )}
                        />
                      </MuiGrid>

                      <MuiGrid item xs={6}>
                        {(showMemoAmount ||
                          values.commitmentType === "MEMO") && (
                          <NumericFormat
                            id="memoAmount"
                            name="memoAmount"
                            label={t(`${formFieldsStr}.memoAmount.label`)}
                            required={!viewOnly}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            fullWidth
                            value={values.memoAmount}
                            helperText={
                              touched.memoAmount ? errors.memoAmount : ""
                            }
                            error={
                              touched.memoAmount && Boolean(errors.memoAmount)
                            }
                            customInput={MuiTextField}
                            variant={viewOnly ? "standard" : "outlined"}
                            isAllowed={(values) => {
                              const { value } = values;
                              return value <= 999999999.99;
                            }}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue("memoAmount", event.target.value);
                            }}
                            autoComplete="off"
                            allowNegative={false}
                            InputProps={{
                              startAdornment: (
                                <MuiInputAdornment position="start">
                                  $
                                </MuiInputAdornment>
                              ),
                            }}
                            sx={disabledTextFieldStyles}
                            disabled={viewOnly}
                            InputLabelProps={{ ...shrinkProps }}
                          />
                        )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Project Group -  Auto populate from Unit */}
                        <CommitmentProjectGroupField
                          name="projectGroup"
                          value={values?.projectGroup?.name || ""}
                          required={!viewOnly}
                          error={
                            touched.projectGroup && Boolean(errors.projectGroup)
                          }
                          helperText={
                            touched.projectGroup ? errors.projectGroup : ""
                          }
                          sx={disabledTextFieldStyles}
                          variant={viewOnly ? "standard" : "outlined"}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* Commitment Unit - MuiAutocomplete Input */}
                        <MuiAutocomplete
                          id="unit"
                          name="unit"
                          options={units}
                          getOptionLabel={(option) => option?.name || ""}
                          value={values.unit}
                          clearIcon={false}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              unit: newValue,
                              projectGroup: newValue?.projectGroup || null,
                            });
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(`${formFieldsStr}.commitmentUnit.label`)}
                              required={!viewOnly}
                              variant={viewOnly ? "standard" : "outlined"}
                              error={
                                touched.unit &&
                                (Boolean(errors.unit) || unitError.exist)
                              }
                              helperText={
                                touched.unit
                                  ? errors.unit || unitError.message
                                  : ""
                              }
                              sx={disabledTextFieldStyles}
                            />
                          )}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Program - MuiAutocomplete Input */}
                      <MuiGrid item xs={6}>
                        <MuiAutocomplete
                          id="program"
                          name="program"
                          options={programs}
                          getOptionLabel={(option) => option?.name || ""}
                          value={values.program}
                          clearIcon={false}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setFieldValue("program", newValue);
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(`${formFieldsStr}.program.label`)}
                              required={!viewOnly}
                              variant={viewOnly ? "standard" : "outlined"}
                              sx={disabledTextFieldStyles}
                              error={
                                touched.program &&
                                (Boolean(errors.program) || programError.exist)
                              }
                              helperText={
                                touched.program
                                  ? errors.program || programError.message
                                  : ""
                              }
                            />
                          )}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={12} />
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Presentation - MuiAutocomplete Input */}
                      <MuiGrid item xs={6}>
                        <MuiAutocomplete
                          id="presentation"
                          name="presentation"
                          options={presentations}
                          getOptionLabel={(option) => option?.name || ""}
                          value={values.presentation}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          clearIcon={false}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              presentation: newValue,
                              presentationSubcategory: null,
                            });
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(`${formFieldsStr}.presentation.label`)}
                              required={!viewOnly}
                              variant={viewOnly ? "standard" : "outlined"}
                              sx={disabledTextFieldStyles}
                              error={
                                touched.presentation &&
                                (Boolean(errors.presentation) ||
                                  presentationError.exist)
                              }
                              helperText={
                                touched.presentation
                                  ? errors.presentation ||
                                    presentationError.message
                                  : ""
                              }
                            />
                          )}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6} />
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Presentation Sub Category - MuiAutocomplete Input */}
                        <CommitmentPresentationSubcategoryField
                          id="presentationSubcategory"
                          name="presentationSubcategory"
                          value={values.presentationSubcategory || ""}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setFieldValue("presentationSubcategory", newValue);
                          }}
                          clearOnEscape
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          sx={disabledTextFieldStyles}
                          variant={viewOnly ? "standard" : "outlined"}
                          InputLabelProps={{ ...shrinkProps }}
                          error={
                            touched.presentationSubcategory &&
                            Boolean(errors.presentationSubcategory)
                          }
                          helperText={
                            touched.presentationSubcategory
                              ? errors.presentationSubcategory &&
                                getIsRequiredText(
                                  t,
                                  t(
                                    `${formFieldsStr}.presentationSubCategory.label`
                                  )
                                )
                              : ""
                          }
                        />
                      </MuiGrid>
                      {values.presentationSubcategory &&
                        values.presentationSubcategory?.name.toLowerCase() ===
                          "co-investment" && (
                          <MuiGrid item xs={6}>
                            {/* Co-Investment Entity - MuiAutocomplete Input */}
                            <MuiAutocomplete
                              id="coInvestmentEntity"
                              name="coInvestmentEntity"
                              options={coInvestmentOptions}
                              getOptionLabel={(option) =>
                                option?.displayText || ""
                              }
                              value={
                                coInvestmentValue ||
                                values.coInvestmentEntity ||
                                ""
                              }
                              onBlur={handleBlur}
                              onChange={(event, newValue) => {
                                setCoInvestmentValue(newValue);
                                setFieldValue("coInvestmentEntity", newValue);
                              }}
                              inputValue={coInvestmentInputValue}
                              onInputChange={(event, newInputValue) => {
                                setCoInvestmentInputValue(newInputValue);
                                newInputValue &&
                                  getCoInvestmentByMatch(
                                    newInputValue,
                                    setCoInvestmentOptions,
                                    setLoading,
                                    setFieldError
                                  );
                              }}
                              clearIcon={false}
                              disabled={viewOnly}
                              forcePopupIcon={!viewOnly}
                              freeSolo
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  label={t(
                                    `${formFieldsStr}.coInvestmentEntity.label`
                                  )}
                                  required={!viewOnly}
                                  variant={viewOnly ? "standard" : "outlined"}
                                  InputProps={{
                                    ...params.InputProps,
                                    onBlur: (event) => {
                                      const value = event.target.value
                                        ? {
                                            displayText: event.target.value,
                                          }
                                        : "";
                                      setCoInvestmentValue(value);
                                      setFieldValue(
                                        "coInvestmentEntity",
                                        value
                                      );
                                    },
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    maxLength: 50,
                                  }}
                                  sx={disabledTextFieldStyles}
                                  helperText={
                                    touched.coInvestmentEntity
                                      ? errors.coInvestmentEntity
                                      : ""
                                  }
                                  error={
                                    touched.coInvestmentEntity &&
                                    Boolean(errors.coInvestmentEntity)
                                  }
                                />
                              )}
                            />
                          </MuiGrid>
                        )}
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/*Commitment Notes - Multiline Textfield Input */}
                      <MuiGrid item xs={12}>
                        <MuiTextField
                          id="commitmentNotes"
                          name="commitmentNotes"
                          label={t(`${formFieldsStr}.commitmentNotes.label`)}
                          multiline
                          value={values.commitmentNotes}
                          onChange={(event, newValue) => {
                            setFieldValue("commitmentNotes", newValue);
                          }}
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 1000 }}
                          disabled={viewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Closed - Checkbox Input */}
                      {!viewOnly ? (
                        <MuiGrid item xs={2} container alignItems="center">
                          <MuiCheckbox
                            id="isClosed"
                            name="isClosed"
                            sx={{
                              padding: 0,
                              color: Theme.palette.text.primary,
                            }}
                            color="default"
                            checked={values.isClosed}
                            onChange={(event) => {
                              setValues({
                                ...values,
                                isClosed: event.target.checked,
                                closedDate: event.target.checked
                                  ? new Date()
                                  : null,
                                closedNotes: "",
                              });
                            }}
                          />{" "}
                          &nbsp;{" "}
                          <MuiTypography display="inline">
                            {t(`${formFieldsStr}.closed.label`)}
                          </MuiTypography>
                        </MuiGrid>
                      ) : (
                        <MuiGrid item xs={6}>
                          <MuiTextField
                            label={t(
                              `${commitmentComponentList}.tableColumns.status`
                            )}
                            value={viewClosedWithdrawnStatus(
                              values.isClosed,
                              values.withdrawn
                            )}
                            disabled
                            fullWidth
                            sx={disabledTextFieldStyles}
                            variant={viewOnly ? "standard" : "outlined"}
                          />
                        </MuiGrid>
                      )}

                      {/* Withdrawn - Checkbox Input  */}
                      {!viewOnly && values.isClosed && (
                        <MuiGrid item xs={4} container alignItems="center">
                          <MuiCheckbox
                            id="withdrawn"
                            name="withdrawn"
                            sx={{
                              padding: 0,
                              color: Theme.palette.text.primary,
                            }}
                            color="default"
                            checked={values.withdrawn}
                            onChange={(event) => {
                              setFieldValue("withdrawn", event.target.checked);
                            }}
                          />{" "}
                          &nbsp;{" "}
                          <MuiTypography display="inline">
                            {t(`${formFieldsStr}.withdrawn.label`)}
                          </MuiTypography>
                        </MuiGrid>
                      )}
                      {values.isClosed && (
                        <MuiGrid item xs={6}>
                          <MuiLocalizationProvider
                            dateAdapter={MuiAdapterDateFns}
                          >
                            <MuiDatePicker
                              id="closedDate"
                              name="closedDate"
                              format="MM/dd/yyyy"
                              label={t(`${formFieldsStr}.closedDate.label`)}
                              value={values.closedDate}
                              onChange={(newValue) => {
                                setFieldValue("closedDate", newValue);
                              }}
                              slotProps={{
                                textField: {
                                  onBlur: handleBlur,
                                  required: !viewOnly,
                                  error: Boolean(errors.closedDate),
                                  helperText: errors.closedDate,
                                  variant: viewOnly ? "standard" : "outlined",
                                  fullWidth: true,
                                },
                              }}
                              disabled={viewOnly}
                              sx={disabledTextFieldStyles}
                            />
                          </MuiLocalizationProvider>
                        </MuiGrid>
                      )}
                    </MuiGrid>
                    {/* Closed Notes - Multiline Textfield Input */}
                    {values.isClosed && (
                      <MuiGrid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <MuiGrid item xs={12}>
                          <MuiTextField
                            id="closedNotes"
                            name="closedNotes"
                            label={t(`${formFieldsStr}.closedNotes.label`)}
                            required={!viewOnly}
                            sx={disabledTextFieldStyles}
                            multiline
                            fullWidth
                            value={values.closedNotes}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue("closedNotes", event.target.value);
                            }}
                            error={
                              touched.closedNotes && Boolean(errors.closedNotes)
                            }
                            helperText={
                              touched.closedNotes ? errors.closedNotes : ""
                            }
                            variant={viewOnly ? "standard" : "outlined"}
                            autoComplete="off"
                            inputProps={{ maxLength: 200 }}
                            disabled={viewOnly}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    )}

                    {!viewOnly ? (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled: !dirty || !isValid,
                            }}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    ) : (
                      !otherParent && (
                        <MuiGrid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {/* Back Button */}
                          <MuiGrid container item justifyContent="flex-end">
                            <MuiButton
                              variant="outlined"
                              size="large"
                              startIcon={<MuiArrowBackIcon />}
                              onClick={() => navigate(-1)}
                            >
                              {t(`${commitmentViewStr}.goBackButtonLabel`)}
                            </MuiButton>
                          </MuiGrid>
                        </MuiGrid>
                      )
                    )}
                  </MuiGrid>
                </form>
              </MuiGrid>
            );
          }}
        </Formik>
      </MuiGrid>
    )
  );
};
