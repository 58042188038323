import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAlerts } from "common";
import { getBudgetFiscalYear } from "./budgetingService";
import { getDepartmentByMatch } from "./orgsService";

export const BudgetingContext = createContext();

export const BudgetingProvider = ({ children }) => {
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(false);

  // State for departments & dept selection
  const [orgs, setOrgs] = useState([]);
  const [orgCode, setOrgCode] = useState("");
  const [orgName, setOrgName] = useState("");
  const [deptSelection, setDeptSelection] = useState([]);
  const [orgByCodeError, setOrgByCodeError] = useState({
    exist: false,
    message: null,
  });

  // Commitment Type Filters
  const [budgetType, setBudgetType] = useState("ALL");

  // Fiscal Year values
  const [budgetFiscalYear, setBudgetFiscalYear] = useState("");
  const [budgetFiscalYearResponse, setBudgetFiscalYearResponse] = useState({});

  // Fiscal Year Edit Dialog states
  const [refreshInformationPanel, setRefreshInformationPanel] = useState(true);
  const [infoPanelDisabled, setInfoPanelDisabled] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const getQueryParams = () => {
    return {
      budgetFiscalYear,
      orgCode: orgCode || undefined,
      budgetType,
    };
  };

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    refreshInformationPanel &&
      getBudgetFiscalYear(
        setBudgetFiscalYearResponse,
        setBudgetFiscalYear,
        setAlert,
        setLoading,
        clearAlert,
        cancelSource
      );

    getDepartmentByMatch(
      true,
      setOrgs,
      setLoading,
      setOrgByCodeError,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [refreshInformationPanel, setBudgetFiscalYear, setAlert, clearAlert]);

  if (orgs.length > 0 && orgCode === "" && orgName === "") {
    setDeptSelection(orgs[0]);
    setOrgCode(orgs[0].value.orgCode);
    setOrgName(orgs[0].value.orgName);
  }

  return (
    <BudgetingContext.Provider
      value={{
        orgs,
        setOrgs,
        orgCode,
        setOrgCode,
        orgName,
        setOrgName,
        deptSelection,
        setDeptSelection,
        orgByCodeError,
        setOrgByCodeError,
        infoPanelDisabled,
        setInfoPanelDisabled,
        infoDialogOpen,
        setInfoDialogOpen,
        budgetType,
        setBudgetType,
        budgetFiscalYear,
        setBudgetFiscalYear,
        budgetFiscalYearResponse,
        setBudgetFiscalYearResponse,
        refreshInformationPanel,
        setRefreshInformationPanel,
        loading,
        setLoading,
        getQueryParams,
      }}
    >
      {children}
    </BudgetingContext.Provider>
  );
};

export const useBudgetingContext = () => {
  return useContext(BudgetingContext);
};
