import { createContext, useContext, useEffect, useState } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";

export const PtaContext = createContext();

export const PtaProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const params = qs.parse(location.search);
  const [ptaFilters, setPtaFilters] = useState({
    project: params.project || "",
    task: params.task || "",
    award: params.award || "",
    projectName: params.projectName || "",
    projectOwner: params.projectOwner || "",
    taskOwner: params.taskOwner || "",
  });

  // State for Sort
  const [sort, setSort] = useState({
    column: "ptaNumber",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  useEffect(() => {
    if (location.pathname.split("/")[1] !== "ptas") {
      setPtaFilters({
        project: params.project || "",
        task: params.task || "",
        award: params.award || "",
        projectName: params.projectName || "",
        projectOwner: params.projectOwner || "",
        taskOwner: params.taskOwner || "",
      });
      setSort({
        column: "ptaNumber",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
    }
  }, [
    location,
    params.project,
    params.task,
    params.award,
    params.projectName,
    params.projectOwner,
    params.taskOwner,
  ]);

  return (
    <PtaContext.Provider
      value={{
        sort,
        setSort,
        pagination,
        setPagination,
        ptaFilters,
        setPtaFilters,
      }}
    >
      {children}
    </PtaContext.Provider>
  );
};

export const usePtaContext = () => {
  return useContext(PtaContext);
};
