import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Autocomplete as MuiAutocomplete,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TextField as MuiTextField,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  Alert as MuiAlert,
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { Help as MuiHelpIcon } from "@mui/icons-material";
import { FormButton } from "app/shared/ui/FormButton";
import { Theme } from "common";
import {
  getCommitmentByIdSummary,
  getCommitmentNameByMatch,
} from "app/services/commitmentsService";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { postMoveComponent } from "app/services/componentsService";

const ComponentTable = ({ t, moveComponentStr, currentComponent }) => {
  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable aria-label="component-table">
        <MuiTableHead
          sx={{
            background: Theme.palette.primary.light,
          }}
        >
          <MuiTableRow>
            <MuiTableCell width={300}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentName`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentDate`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentType`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={160}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.pta`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.unspentAmount`)}
              </MuiTypography>
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          <MuiTableRow>
            <MuiTableCell>
              <MuiTooltip title={currentComponent?.componentName ?? ""}>
                <MuiTypography
                  variant="body2"
                  noWrap
                  sx={{
                    width: 300,
                    textOverflow: "ellipsis",
                  }}
                >
                  {currentComponent?.componentName ?? ""}
                </MuiTypography>
              </MuiTooltip>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography variant="body2" noWrap>
                {currentComponent?.componentDate ?? ""}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography variant="body2" noWrap>
                {currentComponent?.componentType ?? ""}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTooltip
                title={PTAFormat(
                  currentComponent?.project ?? "",
                  currentComponent?.task ?? "",
                  currentComponent?.award ?? ""
                )}
              >
                <MuiTypography
                  variant="body2"
                  noWrap
                  sx={{
                    width: 160,
                    textOverflow: "ellipsis",
                  }}
                >
                  {PTAFormat(
                    currentComponent?.project ?? "",
                    currentComponent?.task ?? "",
                    currentComponent?.award ?? ""
                  )}
                </MuiTypography>
              </MuiTooltip>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography variant="body2" noWrap>
                {CurrencyFormat(currentComponent?.totalUnspentAmount ?? "")}
              </MuiTypography>
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

const CommitmentTable = ({
  t,
  moveComponentStr,
  currentComponent,
  formDataResponse,
}) => {
  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable aria-label="component-table">
        <MuiTableHead
          sx={{
            background: Theme.palette.primary.light,
          }}
        >
          <MuiTableRow>
            <MuiTableCell width={300}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentName`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentDate`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentType`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={160}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.pta`)}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width={140}>
              <MuiTypography variant="subtitle1" noWrap>
                {t(`${moveComponentStr}.componentAmount`)}
              </MuiTypography>
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          <MuiTableRow>
            {currentComponent && formDataResponse ? (
              <>
                <MuiTableCell>
                  <MuiTooltip
                    title={t(`${moveComponentStr}.movedComponentName`, {
                      componentName: currentComponent?.componentName,
                    })}
                  >
                    <MuiTypography
                      variant="body2"
                      noWrap
                      sx={{
                        width: 300,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {t(`${moveComponentStr}.movedComponentName`, {
                        componentName: currentComponent?.componentName,
                      })}
                    </MuiTypography>
                  </MuiTooltip>
                </MuiTableCell>
                <MuiTableCell>
                  <MuiTypography variant="body2" noWrap>
                    {currentComponent?.componentDate}
                  </MuiTypography>
                </MuiTableCell>
                <MuiTableCell>
                  <MuiTypography variant="body2" noWrap>
                    {currentComponent?.componentType}
                  </MuiTypography>
                </MuiTableCell>
                <MuiTableCell>
                  <MuiTooltip
                    title={PTAFormat(
                      currentComponent?.project ?? "",
                      currentComponent?.task &&
                        t(`${moveComponentStr}.movedTaskName`, {
                          taskName: currentComponent?.task,
                        }),
                      currentComponent?.award ?? ""
                    )}
                  >
                    <MuiTypography
                      variant="body2"
                      noWrap
                      sx={{
                        width: 160,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {PTAFormat(
                        currentComponent?.project ?? "",
                        currentComponent?.task &&
                          t(`${moveComponentStr}.movedTaskName`, {
                            taskName: currentComponent?.task ?? "",
                          }),
                        currentComponent?.award ?? ""
                      )}
                    </MuiTypography>
                  </MuiTooltip>
                </MuiTableCell>
                <MuiTableCell>
                  <MuiTypography variant="body2" noWrap>
                    {CurrencyFormat(currentComponent?.totalUnspentAmount ?? "")}
                  </MuiTypography>
                </MuiTableCell>
              </>
            ) : (
              <MuiTableCell colSpan={5} align="center">
                <MuiTypography variant="body2" noWrap>
                  {t(`${moveComponentStr}.noData`)}
                </MuiTypography>
              </MuiTableCell>
            )}
          </MuiTableRow>
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

export const MoveComponentDialog = ({
  open,
  closeMoveComponentDialog,
  currentComponent,
  currentCommitment,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const moveComponentStr = "Commitments.moveComponent";
  const [newCommitmentValue, setNewCommitmentValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [alert, setAlert] = useState({
    type: "",
    message: null,
  });

  const clearAlert = () => {
    setAlert({
      type: "",
      message: null,
    });
  };

  // State variable and its function for Commitment Options
  const [commitmentNameOptions, setCommitmentNameOptions] = useState([]);
  const [commitmentNameFilterError, setCommitmentNameFilterError] = useState({
    exist: false,
    message: null,
  });
  const [, setHasDocument] = useState({});
  const [formDataResponse, setFormDataResponse] = useState(null);
  const [departmentNameValue, setDepartmentNameValue] = useState(null);

  const handleCommitmentNameAutocomepleteClear = () => {
    setFormDataResponse(null);
    setDepartmentNameValue(null);
    setNewCommitmentValue(null);
    setAlert({
      type: "",
      message: null,
    });
  };

  const closeDialog = () => {
    closeMoveComponentDialog();
    handleCommitmentNameAutocomepleteClear();
  };

  const handleSubmit = () => {
    const { id: targetCommitmentId, name: targetCommitmentName } =
      newCommitmentValue;
    const { id: sourceComponentId } = currentComponent;

    const movedComponentValue = {
      targetCommitment: {
        id: targetCommitmentId,
        name: targetCommitmentName,
      },
      targetComponentName: t(`${moveComponentStr}.movedComponentName`, {
        componentName: currentComponent?.componentName,
      }),
    };
    postMoveComponent(
      targetCommitmentId,
      sourceComponentId,
      movedComponentValue,
      clearAlert,
      setAlert,
      t,
      navigate,
      closeDialog,
      setLoading
    );
  };
  return (
    <MuiDialog
      open={open}
      onClose={closeMoveComponentDialog}
      fullWidth
      PaperProps={{
        sx: {
          width: "1048px",
          maxWidth: "100%",
        },
      }}
    >
      <MuiBackdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <MuiCircularProgress color="inherit" />
      </MuiBackdrop>
      <MuiDialogTitle>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item>
            <MuiTypography variant="h2">
              {t(`${moveComponentStr}.title`)}
            </MuiTypography>
          </MuiGrid>
          <MuiGrid item>
            <MuiTooltip title={t(`${moveComponentStr}.helpText`)}>
              <MuiHelpIcon fontSize="small" />
            </MuiTooltip>
          </MuiGrid>
        </MuiGrid>
        {alert?.message && (
          <MuiAlert title={alert.message} severity={alert.type}>
            {alert.message}
          </MuiAlert>
        )}
      </MuiDialogTitle>
      <MuiDialogContent sx={{ paddingBottom: 0 }}>
        <MuiGrid container gap={2}>
          <MuiGrid
            container
            item
            xs={12}
            gap={2}
            padding={2}
            justifyContent="space-between"
            sx={{
              borderRadius: 1,
              border: `1px solid ${Theme.palette.grey[300]}`,
            }}
          >
            <MuiGrid item xs={5}>
              <MuiTextField
                label={t(`${moveComponentStr}.currentCommitment`)}
                variant="standard"
                value={currentCommitment.name}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </MuiGrid>
            <MuiGrid item xs={5}>
              <MuiTextField
                label={t(`${moveComponentStr}.currentDepartment`)}
                variant="standard"
                value={currentCommitment.departmentName}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </MuiGrid>
            <MuiGrid item xs={12}>
              <ComponentTable
                t={t}
                moveComponentStr={moveComponentStr}
                currentComponent={currentComponent}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid
            container
            item
            xs={12}
            gap={2}
            padding={2}
            justifyContent="space-between"
          >
            <MuiGrid container item xs={12} justifyContent="space-between">
              <MuiGrid item xs={6}>
                <MuiAutocomplete
                  sx={{ paddingTop: 1 }}
                  size="small"
                  loading={autoCompleteLoading}
                  options={commitmentNameOptions || []}
                  getOptionLabel={(option) => option && option.name}
                  value={newCommitmentValue ?? ""}
                  onChange={(event, newValue) => {
                    setNewCommitmentValue(newValue);

                    if (newValue) {
                      const cancelSource = axios.CancelToken.source();
                      const { id: CommitmentId } = newValue;
                      getCommitmentByIdSummary(
                        CommitmentId,
                        {
                          setFormDataResponse,
                          setHasDocument,
                          setDepartmentNameValue,
                          setLoading: setAutoCompleteLoading,
                        },
                        {
                          setAlert,
                          clearAlert,
                        },
                        cancelSource
                      );
                    } else {
                      handleCommitmentNameAutocomepleteClear();
                    }
                  }}
                  onClose={() => setCommitmentNameOptions([])}
                  noOptionsText={t(`${moveComponentStr}.noOptionsText`)}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={t(`${moveComponentStr}.newCommitment`)}
                      onChange={(e, value) => {
                        getCommitmentNameByMatch(
                          e.target.value,
                          setCommitmentNameOptions,
                          setAutoCompleteLoading,
                          setCommitmentNameFilterError,
                          "value"
                        );
                      }}
                      error={commitmentNameFilterError.exist}
                      helperText={commitmentNameFilterError.message}
                    />
                  )}
                />
              </MuiGrid>
              <MuiGrid item xs={5}>
                <MuiTextField
                  label={t(`${moveComponentStr}.newDepartment`)}
                  variant="standard"
                  value={departmentNameValue?.orgName ?? " "}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </MuiGrid>
            </MuiGrid>
            <MuiGrid item xs={12}>
              {
                <CommitmentTable
                  t={t}
                  moveComponentStr={moveComponentStr}
                  currentComponent={currentComponent}
                  formDataResponse={formDataResponse}
                />
              }
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiGrid
          container
          item
          justifyContent="flex-end"
          xs={3}
          spacing={2}
          pr={2}
        >
          <FormButton
            cancel={closeDialog}
            save={{
              disabled: !formDataResponse,
              onClick: handleSubmit,
            }}
          />
        </MuiGrid>
      </MuiDialogActions>
    </MuiDialog>
  );
};

//Formatting of Project,task and string
const PTAFormat = (project, task, award) => {
  return `${project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-${
    task ? task : "\u00A0\u00A0\u00A0"
  }-${award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"}`;
};
