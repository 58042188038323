import { useEffect, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Grid as MuiGrid,
  Link as MuiLink,
  Paper as MuiPaper,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Autocomplete as MuiAutocomplete,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import {
  ArrowBack as MuiArrowBackIcon,
  FileDownload as MuiFileDownloadIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { Theme, useAlerts, useUsers } from "common";
import {
  getCommitmentByIdFull,
  getMyDepartmentsByMatch,
  postCommitment,
  putCommitment,
  deleteCommitment,
  prepareCommitmentData,
  getCurrentFiscalYear,
} from "app/services/commitmentsService";
import { getComponentRequestTemplatesByType } from "app/services/componentsService";
import { LinkToFacultyNewHireTemplate } from "app/shared/constants";
import { FormButton } from "app/shared/ui/FormButton";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";
import { CommitmentComponentRequestTable } from "app/components/Commitments/CommitmentComponents/CommitmentComponentRequest/CommitmentComponentRequestTable";

/**
 * Component export definition for `CommitmentForm`
 * It will be used as a form to create and edit a commitment
 *
 */
export const CommitmentRequestForm = ({ pageType, parent }) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();

  const params = useParams();

  const isViewOnly = pageType === "view" || pageType === "delete";
  const isDeleteRecord = pageType === "delete";
  const isEditRecord = pageType === "edit";
  const shrinkProps = isViewOnly && { shrink: true };
  const otherParent = parent === "other";
  const navigate = useNavigate();
  const location = useLocation();
  const commitmentType = "REQUEST";

  let {
    orgCode: sourceOrgCode,
    orgName: sourceOrgName,
    currentFiscalYear,
    budgetEditableByCurrentUser,
  } = location.state || {};
  currentFiscalYear = currentFiscalYear || getCurrentFiscalYear();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const { clearAlert, setAlert } = useAlerts();

  const formFieldsStr = "Commitments.create.form.fields";

  const [loading, setLoading] = useState(false);

  // State variable and its function for orgOptions
  const [orgOptions, setOrgOptions] = useState([]);
  const [commitmentRequestType, setCommitmentRequestType] =
    useState("NON_RECRUITMENT");
  const [templatedComponents, setTemplatedComponents] = useState([]);

  const [departmentNameValue, setDepartmentNameValue] = useState({
    orgName: sourceOrgName,
    orgCode: sourceOrgCode,
  });

  const getEmptyComponent = () => ({
    componentType: "REQUEST",
    budgetPlan: {
      budgetFiscalYear: currentFiscalYear,
      fyProjectionOne: 0,
      fyBudget: 0,
      fyPlanOne: 0,
      fyPlanTwo: 0,
    },
    componentAmount: 0,
    startDate: null,
    expirationDate: null,
  });

  const getBreadcrumbTxt = () => {
    if (pageType === "new") {
      return t(`Commitments.createRequest.breadcrumbTitle`);
    } else if (isDeleteRecord) {
      return t(`Commitments.deleteRequest.breadcrumbTitle`);
    } else if (isViewOnly) {
      return t(`Commitments.viewRequest.breadcrumbTitle`);
    } else {
      return t(`Commitments.editRequest.breadcrumbTitle`);
    }
  };

  const getMainHeadingTxt = () => {
    if (pageType === "new") {
      return t(`Commitments.createRequest.title`);
    } else if (isDeleteRecord) {
      return (
        t(`Commitments.deleteRequest.title`) + ": " + formDataResponse.name
      );
    } else if (isViewOnly) {
      return t(`Commitments.viewRequest.title`) + ": " + formDataResponse.name;
    } else {
      return t(`Commitments.editRequest.title`) + ": " + formDataResponse.name;
    }
  };

  const inputVariant = isViewOnly ? "standard" : "outlined";
  const isRequired = !isViewOnly;

  /**
   * `formDataResponse` - Use to store the response of create/edit form data
   * `setFormDataResponse` - Use to set/modify the value of state/variable formDataResponse
   */
  const formPreFetchData = {
    commitmentType: commitmentType, //Enum implementation
  };

  const [, setHasDocument] = useState({});
  const [formDataResponse, setFormDataResponse] = useState({
    // Object keys use to create/edit form data
    name /* Commitment Name */: "",
    departmentName /* Department Name */: {
      orgName: sourceOrgName,
      orgCode: sourceOrgCode,
    },
    commitmentType /* Commitment Type */: commitmentType,
    commitmentNotes /* Commitment Notes */: "",
    orgCode: sourceOrgCode,
    components: [getEmptyComponent()],
  });

  const commitmentValidation = Yup.object().shape({
    name: Yup.string().required(
      t(`${formFieldsStr}.commitmentName.validation.isEmpty`)
    ),
    departmentName: Yup.mixed().required(
      t(`${formFieldsStr}.departmentName.validation.isEmpty`)
    ),
    downloadClicked: Yup.object().test(
      "isRequired",
      "You must click the download button to continue",
      (value) => {
        return (
          !!!value ||
          value?.requestType === "NON_RECRUITMENT" ||
          (value?.requestType === "RECRUITMENT" && value?.clicked === true)
        );
      }
    ),
    components: Yup.array()
      .of(
        Yup.object().shape({
          componentName: Yup.string()
            .required(
              t(
                "Commitments.commitmentComponents.create.form.fields.componentName.validation.isEmpty"
              )
            )
            .test(
              "isDuplicate",
              t(
                "Commitments.commitmentComponents.create.form.fields.componentName.validation.isDuplicate"
              ),
              function () {
                let testedObject = this.parent;
                let propertyName = "componentName";
                if (
                  this.from[1].value.components
                    // Exclude the same object (by reference)
                    .filter((object) => object !== testedObject)

                    // Check for property match among some of the other objects
                    .some((object) => {
                      const objectValue = get(object, propertyName);
                      const testedObjectValue = get(testedObject, propertyName);
                      return objectValue === testedObjectValue;
                    })
                ) {
                  return false;
                }
                return true;
              }
            ),
        })
      )
      .compact(),
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    // Fetch data to be edited
    params.CommitmentId &&
      getCommitmentByIdFull(
        params.CommitmentId,
        {
          setFormDataResponse,
          setHasDocument,
          setDepartmentNameValue,
          setLoading,
        },
        {
          setAlert,
          clearAlert,
        },
        cancelSource
      );
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.CommitmentId]);

  // Load the templated components for recruitment commitments
  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    if (
      commitmentRequestType === "RECRUITMENT" &&
      templatedComponents?.length === 0
    ) {
      getComponentRequestTemplatesByType(
        commitmentRequestType,
        (valueArr) => {
          setTemplatedComponents(
            valueArr.map((tmp) => {
              let o = getEmptyComponent();
              return {
                ...o,
                componentName: tmp.name,
                templateSourceType: commitmentRequestType,
              };
            })
          );
        },
        setLoading,
        clearAlert,
        setAlert
      );
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [commitmentRequestType]);

  const handleClose = () => {
    redirectToBudgetingTab(navigate);
  };

  const handleDelete = () => {
    // Defines what to do after deleting the data
    const afterSave = () => {
      redirectToBudgetingTab(navigate);
      const textKey = "Commitments.delete.notification.success";
      setAlert("success", t(textKey), true);
    };

    deleteCommitment(
      params.CommitmentId,
      setLoading,
      t,
      clearAlert,
      setAlert,
      afterSave
    );
  };

  const _handleSubmit = (values, setFieldError) => {
    const cancelSource = axios.CancelToken.source();
    values = prepareCommitmentData(values, formPreFetchData, currentFiscalYear);

    // Defines what to do after saving the data
    const afterSave = () => {
      redirectToBudgetingTab(navigate);
      const textKey = params.CommitmentId
        ? "Commitments.create.notification.update"
        : "Commitments.create.notification.success";
      setAlert(
        "success",
        t(textKey, { commitmentName: `"${values.name.trim()}"` }),
        true
      );
    };

    params.CommitmentId
      ? putCommitment(
          values,
          params.CommitmentId,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        )
      : postCommitment(
          values,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        );
  };

  return (
    ((pageType === "new" && permissions.CREATE_REQUEST_COMMITMENTS) ||
      (pageType === "edit" && permissions.EDIT_REQUEST_COMMITMENTS) ||
      (pageType === "delete" && permissions.DELETE_REQUEST_COMMITMENTS) ||
      (pageType === "view" && permissions.VIEW_REQUEST_COMMITMENTS)) && (
      <MuiGrid container>
        {/* Breadcrumb Navigation & Back Button */}
        {pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/budgeting`}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("Budgeting.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {getBreadcrumbTxt()}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>
            {isViewOnly && !otherParent && (
              <MuiGrid
                item
                container
                xs={4}
                justifyContent="flex-end"
                spacing={2}
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/budgeting`}
                >
                  <MuiButton startIcon={<MuiArrowBackIcon />}>
                    {t(`Commitments.view.goBackButtonLabel`)}
                  </MuiButton>
                </RouterLink>
              </MuiGrid>
            )}
          </MuiGrid>
        )}
        <MuiGrid item xs={12}>
          <MuiTypography variant="h1">{getMainHeadingTxt()}</MuiTypography>
        </MuiGrid>
        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
          to create/edit a commitment */}
        <Formik
          // initialValues - User to store the Formik form's initial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError);
          }}
          validationSchema={commitmentValidation}
          validateOnMount={isEditRecord}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                dirty,
                isValid,
                setFieldError,
                setValues,
              } = formikProps;
            if (!isEditRecord && !isDeleteRecord) {
              handleIfIsRecruitment({
                templatedComponents,
                values,
                setValues,
                getEmptyComponent,
              });
            }
            return (
              // Native form element to submit the form values
              <MuiGrid item xs={10}>
                <form onSubmit={handleSubmit}>
                  {/* FormControlContainer - Flex container to wrap all the form flex items */}
                  <MuiGrid item container xs={12} direction="column" gap="16px">
                    <MuiGrid
                      item
                      xs={10}
                      container
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">&nbsp;</MuiTypography>
                      </MuiGrid>
                    </MuiGrid>

                    {/* Commitment Name - MuiTextField Input */}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        <MuiTextField
                          id="name"
                          label={t(`${formFieldsStr}.commitmentName.label`)}
                          required={isRequired}
                          value={values.name}
                          onChange={handleChange}
                          fullWidth
                          helperText={errors.name ? errors.name : ""}
                          error={errors.name && Boolean(errors.name)}
                          variant={inputVariant}
                          disabled={isViewOnly}
                          autoComplete="off"
                          inputProps={{ maxLength: 100 }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {/* Department Name - Autocomplete Input */}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        <MuiAutocomplete
                          id="departmentName"
                          name="departmentName"
                          options={orgOptions || []}
                          getOptionLabel={(option) =>
                            option?.orgName && option?.orgCode
                              ? `${option.orgName} (${option.orgCode})`
                              : ""
                          }
                          value={departmentNameValue}
                          clearIcon={false}
                          disabled={isViewOnly}
                          forcePopupIcon={!isViewOnly}
                          onChange={(event, newValue) => {
                            const orgCode = newValue?.orgCode || "";
                            setDepartmentNameValue(newValue);
                            setFieldValue("orgCode", orgCode);
                            setFieldValue("departmentName", newValue);
                          }}
                          onInputChange={(event, newInputValue) => {
                            const searchText = newInputValue.includes(
                              sourceOrgCode
                            )
                              ? ""
                              : newInputValue;
                            getMyDepartmentsByMatch(
                              searchText,
                              setOrgOptions,
                              setLoading,
                              setFieldError
                            );
                          }}
                          renderInput={(args) => (
                            <MuiTextField
                              {...args}
                              helperText={
                                errors.departmentName
                                  ? errors.departmentName
                                  : ""
                              }
                              error={Boolean(errors?.departmentName)}
                              sx={disabledTextFieldStyles}
                              label={t(`${formFieldsStr}.departmentName.label`)}
                              required={isRequired}
                              variant={isViewOnly ? "standard" : "outlined"}
                            />
                          )}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {/* Commitment Notes - Multiline Textfield Input */}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={12}>
                        <MuiTextField
                          id="commitmentNotes"
                          label={t(`${formFieldsStr}.commitmentNotes.label`)}
                          multiline
                          value={values.commitmentNotes}
                          onChange={(event) => {
                            setFieldValue(
                              "commitmentNotes",
                              event.target.value
                            );
                          }}
                          variant={inputVariant}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 1000 }}
                          disabled={isViewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {/* Component Table */}
                    <MuiGrid item container xs={10}>
                      {/*Recruitment - Checkbox Input */}
                      <MuiGrid item xs={12}>
                        <MuiFormControlLabel
                          label={t(`${formFieldsStr}.isRecruitment.label`)}
                          control={
                            <MuiCheckbox
                              checked={
                                commitmentRequestType === "RECRUITMENT" ||
                                values.commitmentRequestType === "RECRUITMENT"
                              }
                              onChange={(e) => {
                                if (!e.target.readOnly) {
                                  const requestType = e.target.checked
                                    ? "RECRUITMENT"
                                    : "NON_RECRUITMENT";
                                  setCommitmentRequestType(requestType);
                                  setTemplatedComponents([]);
                                  setValues({
                                    ...values,
                                    downloadClicked: {
                                      requestType,
                                      clicked: false,
                                    },
                                  });
                                }
                              }}
                              inputProps={{
                                readOnly: isEditRecord,
                              }}
                            />
                          }
                        />
                      </MuiGrid>

                      {(commitmentRequestType === "RECRUITMENT" ||
                        values.commitmentRequestType === "RECRUITMENT") && (
                        <MuiGrid item xs={12}>
                          <MuiPaper
                            elevation={4}
                            sx={{
                              "&.MuiPaper-root": {
                                padding: "10px 16px",
                                marginBottom: "16px",
                                backgroundColor: Theme.palette.primary.light,
                              },
                            }}
                          >
                            <p>
                              {t("Commitments.create.recruitment.dialogText")}
                            </p>
                            <p>
                              <MuiButton
                                component={MuiLink}
                                href={LinkToFacultyNewHireTemplate.downloadUrl}
                                download={LinkToFacultyNewHireTemplate.filename}
                                variant="outlined"
                                color={
                                  errors.downloadClicked ? "error" : "inherit"
                                }
                                sx={{
                                  "&.MuiButton-outlinedError": {
                                    color: Theme.palette.error.main,
                                  },
                                }}
                                startIcon={<MuiFileDownloadIcon />}
                                onClick={(_event) => {
                                  setValues({
                                    ...values,
                                    downloadClicked: {
                                      requestType: commitmentRequestType,
                                      clicked: true,
                                    },
                                  });
                                }}
                              >
                                {t(
                                  "Commitments.create.recruitment.templateDownloadTooltip"
                                )}
                                <sup>*</sup>
                              </MuiButton>
                            </p>
                          </MuiPaper>
                        </MuiGrid>
                      )}
                      <CommitmentComponentRequestTable
                        loading={loading}
                        isViewOnly={isViewOnly}
                        isEditRecord={isEditRecord}
                        isDeleteRecord={isDeleteRecord}
                        inputVariant={inputVariant}
                        currentFiscalYear={currentFiscalYear}
                        getEmptyComponent={getEmptyComponent}
                        budgetEditableByCurrentUser={
                          budgetEditableByCurrentUser
                        }
                        {...formikProps}
                      />
                    </MuiGrid>

                    {/* Bottom Navigation / Form Controls */}
                    {!isViewOnly && (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                        gap="16px"
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled: !dirty || !isValid || loading,
                            }}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    )}
                    {isDeleteRecord && (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        gap="16px"
                        spacing={2}
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            delete={handleDelete}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    )}
                    {isViewOnly && !isDeleteRecord && !otherParent && (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        gap="16px"
                        spacing={2}
                      >
                        {/* Back Button */}
                        <MuiGrid container item justifyContent="flex-end">
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={`/commitments`}
                          >
                            <MuiButton
                              variant="outlined"
                              size="large"
                              startIcon={<MuiArrowBackIcon />}
                            >
                              {t(`Commitments.view.goBackButtonLabel`)}
                            </MuiButton>
                          </RouterLink>
                        </MuiGrid>
                      </MuiGrid>
                    )}
                  </MuiGrid>
                </form>
              </MuiGrid>
            );
          }}
        </Formik>
      </MuiGrid>
    )
  );
};

const redirectToBudgetingTab = (navigate) => {
  navigate("/budgeting");
};

const handleIfIsRecruitment = ({
  templatedComponents,
  values,
  setValues,
  getEmptyComponent,
}) => {
  const currentTemplatedRows = values.components?.filter((f) =>
    f?.hasOwnProperty("templateSourceType")
  );

  // Add templated rows
  if (templatedComponents?.length > 0 && currentTemplatedRows?.length === 0) {
    let newValues = values;
    let newSet = templatedComponents
      // Exclude templated objects already in the array
      .filter(
        (x) =>
          !currentTemplatedRows.some((y) => y.componentName === x.componentName)
      );
    newValues.components = [...newSet, ...values.components];

    // Remove any extra/blank rows
    newValues.components = newValues.components.filter(
      (obj) => obj?.componentName && obj?.componentName?.trim() !== ""
    );
    setValues(newValues);
  }

  // Remove templated rows
  if (templatedComponents?.length === 0 && currentTemplatedRows?.length > 0) {
    let newValues = values;
    newValues.components = values.components.filter(
      (o) => !o?.hasOwnProperty("templateSourceType")
    );
    // Ensure at least one component row is left in the table
    if (newValues?.components?.length === 0) {
      newValues?.components.push(getEmptyComponent());
    }
    setValues(newValues);
  }
};
